.enquiry-properties {
    background-color: transparent;

    position: fixed;
    z-index: 100000000 !important;

    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;

    display: flex;

    align-items: center;
    justify-content: center;

    .enquiry-properties-content {
        width: fit-content !important;
        display: flex;
        min-height: 535px;

        .body {
            flex: 2 1 0;
        }
    }
}

.enquiry-properties-tabs-content {
    min-width: 100px;
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
}

.enquiry-properties-main-content {
    padding: 20px 25px;
    padding-bottom: 6px;
    min-width: 400px;

    display: flex;
    flex-direction: column;
    gap: 15px;
}

.header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .x {
        width: 15px;
        height: 15px;

        cursor: pointer;
    }
}

.tabs-header {
    margin-bottom: 20px;
    padding: 0px 25px;
}

.enquiry-properties-tab {
    margin: 0;
    padding: 5px 25px;
    border: 0;
    text-align: left;
}

.buttons-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin: 5px 0px;

    .buttons-wrapper {
        display: flex;
        right: 0px;
        align-items: center;
        gap: 20px;

        button {
            min-width: 60px;
        }
    }
}
.property-tab-disabled {
    pointer-events: none;
}
.tabbed-confirm {
    display: flex;
}