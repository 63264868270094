

.navTab{
 
    z-index:10000 !important;
    width:auto !important;
    padding:0px;margin:0px;
  
} 
.navTab > :nth-child(1){
    display:flex;
      margin:0px -10px !important;
      
    padding:6px 6px 7px 6px;
    
}
 .fixedWidth{min-width:100px  !important;text-align:right;display:block; }
 .fixedWidth >  div{
    padding-left:10px;
 }
.navTab :nth-child(1) > :nth-child(1):hover, .navTab :nth-child(1) > :nth-child(2):hover{
   
    cursor: pointer;
    
}

.navTab :nth-child(1) > :nth-child(2){
   
    padding-left:15px;margin:0px;
    
}
 
  
.tabRow > :first-child{
    
    padding-left:22px;
    padding-right:18px;
}
 