.copy-tag {
    display: flex;
    align-items: center;
    gap: 2px;
}
.copy-icon {
    border: 0;
    background: none;
    padding: 0;
    margin-top: 1px;
    height: fit-content;
}