.dataTable{
    table-layout:fixed;
border:0px !important;
z-index: 100 !important;
min-width: 900px !important;
}
.tableHeader{
    position: sticky;
    top:-1px;
    left:-2px;
    
    margin-left:-2px;
    z-index: 100 !important;
    margin-bottom:5px;
     
}
.dataTable :nth-child(1) th:first-of-type .headerDiv:first-of-type{
    border-left:1px solid #fff;
   
}
.rowClass:focus {outline:none;} // this removes the black border when using keys to navigate between enquiries
.dataTable     tr  td{
    border:solid 0px #ccc;
    border-collapse: collapse;
    -webkit-touch-callout: none;
    -webkit-user-select: none; 
    -khtml-user-select: none; 
     -moz-user-select: none; 
    -ms-user-select: none; 
      user-select: none; 
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
}
.dataTable    tr :focus-visible{outline:3px !important;}
.dataTable    tr  th{ 
    border-collapse: collapse;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
   // padding: 2px 3px 2px 2px;
    background-color: #e6e7e8;
    font-weight: bold ;
    border-top:0px;
 
}
 

 
.resizerDiv{
    border :solid 1px #ccc;
    width:5px;
    height:100%;
}
.headerDiv{
    position: relative;
    width:100%;
    border-right:1px solid #fff;
    
}
.headerSort:hover {
    background-color: #ccc;
    cursor: pointer;
}
.headerDiv:first-child{
    
 
}
.headerDiv :nth-child(2):hover{
    cursor: col-resize;
 
}

.selectResizer{
    border-left:solid 1px #000;
    height:100%;position:absolute;
    width:5px;
    z-index:100002;
    display: none;
}

.spacerRow  td{
    border:0px !important;
    background-color: transparent !important;
}

.sla-priority-bar {
    height: 14px;
    width: 8px;
    transform: rotate(180deg);
}

.sla-priority--value {
        width: 58%;       
    }
