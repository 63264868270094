.RadioButtonRow, .RadioButtonRowActive, .RadioButtonRowDisabled{
    padding:10px;
    margin-Bottom:10px;
    display:flex;
    align-items: center;
 }
 
 .RadioButtonRow:hover, .RadioButtonRowActive:hover{
     padding:10px;
     margin-Bottom:10px;
     cursor: pointer;
  }
  .RadioButtonRowDisabled{
    pointer-events: none;
  }
 
 

.RadioButtonRowCol3{
    width:20px;
    height:20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top:0px;
 }

.RadioButtonRowCol1{
    width:20px;
    padding-top:4px;
 }
.RadioButtonRowCol2{
    flex:1;
    padding-top: 0px;
 }
