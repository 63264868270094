.contact-list {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 5px;
    position: absolute;
    top: 100%;
    margin-top: 2px;
    margin-bottom: 0px;
    min-width: 300px;
    max-width: 600px;
    z-index: 9990;
}

.contact-container {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    gap: 6px;
    cursor: pointer;
    overflow: hidden;
}

.c-contact {
    flex: 2 1 0;
    display: flex;
    flex-direction: column;
    gap: 1px;
}

.contact-tags {
    display: flex;
    align-items: center;
    gap: 8px;
}

.contact-tag {
    padding: 2px 4px;
    border-radius: 2px;
}

.search-button {
    display: flex;
    align-items: center;
}

.search-button .search-input {
    all: unset;
    flex: 2 1 0;
}

.search-button .search-icon {
    padding: 5px 10px;
}

.horizontal-divider {
    height: 1px;
    margin: 4px 0px;
}