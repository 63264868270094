 

body{
    background-color: #F3F3F3;
    padding:0px;
    margin:0px;
    overflow: none !important;
}
 
.index {
    width: 100%;
}

.notice {
    width: fit-content;
    height: fit-content;
    margin: 5px auto;
    padding: 5px 10px;
}

.multi-tenant-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .multi-tenant-login {
        margin-top: min(80px, 5%);
        display: flex;
        flex: 9 1 0;
        flex-direction: column;
        min-width: max(45%, 375px);
        padding: 30px 0px;
        overflow: hidden;

        &--head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            padding: 0px 40px;
            margin: 10px 0px;
            margin-bottom: 15px;

            &--logo {
                background-size: 100% 100%;
            }
        }

        &--tenants {
            flex: 9 1 0;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            &--header {
                font-weight: bold;
                padding: 0px 40px;
                margin: 20px 0px;
            }

            &--lists {
                flex: 9 1 0;
                display: flex;
                flex-direction: column;
                overflow: hidden;

                .all-list {
                    flex: 6 1 0;
                    overflow: hidden;
                }

                .tenant-select {
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                    border: 0px;

                    &:focus {
                        border: 0px !important;
                    }

                    &--option {
                        padding: 10px 40px;
                        margin-bottom: 5px;

                        &:hover {
                            cursor: pointer;
                        }

                        &--label {
                            width: fit-content;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                &--list-header {
                    margin: 10px 0px;
                    font-weight: bold;
                    padding: 0px 40px;
                    font-size: 10pt;
                }

                .tenant-select-recent {
                    max-height: 50%;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    flex: 2 1 0;
                    

                    .recent-tenants {
                        flex: 6 1 0;
                        overflow-y: auto;
                    }
                }
            }
        }
    }
}

.access-denied {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--content {
        display: flex;
        flex-direction: column;
        padding: 20px 30px;
        border: 1px solid;

        &--head {
            font-weight: bold;
        }

        &--message {
            margin-top: 8px;
        }
    }
}

.scroll-small::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.scroll-small::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.scroll-small::-webkit-scrollbar-thumb {
    background-color: #A5AAB3;
    outline: 0px solid #A5AAB3;
}