.enquiries-list {
    height: calc(100vh - 140px);
    padding: 0px !important;
    margin: 0px !important;
    overflow: auto !important;
}

.enquiries-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
 
    padding: 0px 0px 0px 2px;
    margin-right: 0px;
}

.enquiries-tabs {
    display: flex;
    align-items: center;
    gap: 1px;
    padding-top: 0px;
    min-width: 300px;
}

.overflowText{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.enquiries-filter {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 4px;
}

button.tab-button {
    border: 0px;
    background-color: transparent;
}

.tab-button {
    padding: 4px 8px;
    margin: 0px;
    border-radius: 2px;
    cursor: pointer;
    pointer-events: all;

    width: fit-content !important;
    align-items: center;
}



.tab-button.loading {
    pointer-events: none;
}


.hideMenuCol{
    display:grid;
    column-gap: 2px;
    grid-template-columns: (100%);
    grid-template-areas:    "two";
}
.showMenuCol{
    display:grid;
    column-gap: 2px;
    grid-template-columns: (200px,100%);
    grid-template-areas:    "one two";
}

.showMenuCol #listMenu {
    grid-area: one;
    display:block;
    width:250px;
}

.hideMenuCol #listMenu {
    grid-area: one;
    display:none;
}
.enquiry-refresh-button {
    border:0;
    padding:3px;
    background:transparent;
    margin-left:10px;
}
.enquiry-refresh-button.selected {
    background: #D3D3D3;
}
.enquiry-refresh-button:hover {
    background: #E6E7E8;
}