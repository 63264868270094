.pills-input {
    border: 1px solid #CFCFCF;
    flex: 2 1 0;
    border-radius: 2px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 4px 6px;
    column-gap: 6px;
}

.pills-input.focused {
    outline: auto;
}

.pills-input.invalid {
    border-color: red;
}

.pills-input-label {
    display: flex;
    flex-basis: 50px;
    align-items: center;
    justify-content: center;
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 32.5px;
    margin-right: 7px;
}

.pills-input-inner-label {
    border: 0;
    background: white;
}

.pills-input-inner-label:hover {
    text-decoration: underline;
}

.pills-list {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 6px;
}

.pills-input-with-dropdown {
    flex: 2 1 0;
    display: flex;
    position: relative;
}

.pills-input-input {
    all: unset;
    flex: 2 1 0;
}

.pill-elt {
    display: flex;
    align-items: center;
    border: 1px solid #D3D3D3;
    border-radius: 2px;
    min-width: fit-content;

    button {
        border: 0;
        background: none;
    }
}

.contact-container.active {
    outline: auto;
}