#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.limitText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabSelected {
  background: #fff;
  border-Bottom: solid 1px #BFC0C2;
  font-Size: 9pt;
  font-Weight: bold;
  color: #606161;
  text-Align: center;
  width: 80px !important;
  padding-Bottom: 0px;
  padding-Top: 6px;

}

.tabUnselected {
  background: #fff;
  border-Bottom: solid 0px #BFC0C2;
  font-Size: 9pt;
  font-Weight: bold;
  color: #606161;
  text-Align: center;
  width: 80px !important;
  padding-Bottom: 0px;
  padding-Top: 6px;
  cursor: pointer;
}


.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.queuesIconHidden {
  display: none;
}

.queuesIcon {
  font-Size: 12pt;
  color: #AEB0B2;

  margin-Left: -10px;
  padding: 0px 5px 5px;
  margin-Right: 3px;
  cursor: pointer;
}

.queuesIcon:hover {
  color: #0B3F73;
  background: #EEEBE9;
  cursor: pointer;
}

.queueFilter {
  background: #fff;
  font-Size: 9pt;
  font-Weight: bold;
  color: #606161;
  text-Align: right;
  padding-Top: 3px;
  float: right;
}



.popOutIconWorkFlow {
  font-Size: 10pt;
  padding-Left: 15px;
  padding-Top: 10px;
  dsisplay: block;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.leftColumn {
  -ms-flex: 0 0 70px !important;
  flex: 0 0 70px !important;
  padding: 10px;
  padding-top: 15px !important;
}