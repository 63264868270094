 .confirm-comp {
     background-color: transparent;

     position: fixed;
     z-index: 100000000 !important;

     left: 0;
     top: 0;
     width: 100vw;
     height: 100vh;

     display: flex;

     align-items: center;
     justify-content: center;


     .content {
         padding: 20px 25px;
         min-width: 400px;
         width: fit-content !important;
         display: flex;
         gap: 15px;
         flex-direction: column;
         background-color: #FF0000;
         box-shadow: 0px 1px 5px 1px #00000040;

         .header-section {
             display: flex;
             align-items: center;
             justify-content: space-between;

             .header {
                 font-size: 12pt;
                 font-weight: 700;
             }

             .x {
                 width: 15px;
                 height: 15px;

                 cursor: pointer;
             }
         }

         .body {
             font-size: 10pt;
             margin: 5px 0px;
             max-width: 700px;
             min-width: fit-content !important;
             .confirm-message-body {
                 display: flex;
                 gap: 15px;
                 width: 100%;

                

                 .icon {
                     width: 25px;
                     height: 25px;

                     .confirm-message-icon {
                         width: 100%;
                         height: 100%;
                     }
                 }
             }
         }

         .buttons-section {
             display: flex;
             position: relative;
             align-items: center;
             justify-content: space-between;
             gap: 10px;
             margin: 5px 0px;
             font-size: 8pt;

             .buttons-wrapper {
                 display: flex;
                 position: absolute;
                 right: 0px;
                 align-items: center;
                 gap: 20px;

                 button {
                     min-width: 60px;
                 }
             }
         }
     }
 }