  
 
.validationFailed, .validationFailed:focus {
    border: solid 1px #FF0000 !important;
    outline: 0 none;
}

.MISRow.MIS-Divider {
    height: 30px;
}

.MISRow.MIS-Divider :nth-child(1) {
    width: inherit;
}

.MIS-Divider hr {
    border: 0 none;
    height: 2px !important;
    opacity: 1;
}

.validationLabel {
    color: red;
    font-size: 8pt;
    margin-left: 12px;
}

.mis-comment {
    border: 1px solid #cfcfcf;
    border-radius: 3px;
    font-size: 9pt;
    height: 60px;
    padding: 5px;
    min-width: 40ch;
    width: 100%;
    min-height: 30px;
    max-height: 200px !important;
    vertical-align: middle;
}

.MISTextArea {
    resize: none;
    overflow: auto;
    scroll-behavior: smooth;
}

.DisplayDividerRowHeight{
 height:10px;
 padding:0px;
}

.DisplayGapRowHeight{
height:25px;
}

.asterisk{
    font-size:11pt;
    color:red;
}