 

.custom-context-menu {
    position: absolute;
    padding: 5px;
    z-index: 1546465456;
    border-radius: 3px;
    min-width: 150px;
}

.custom-context-menu .option {
    cursor: pointer;
    pointer-events: all;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 6px;
}
.custom-context-menu .option.disabled {
    cursor: default;
    pointer-events: none;
}



.enquiry-list {
    flex: 9 1 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    // overflow-y: auto;
    // overflow-x: auto;
 
   min-width: 965px;
    &--header {
        .enquiry-list-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #000000;
            cursor: pointer;
        }
        .enquiry-list-header:hover {
            background-color: #E6E7E8;
        }
        width: 100%;
          min-width: 955px;
        display: flex;
        align-items: center;
        gap: 4px;
        padding-right: 8px;
        padding-bottom: 5px;
        margin-top:0px;padding-top:0px;
        top:0px;
        position: sticky;
        z-index:50000;
 
        &--bar {
            width: 5.938px;
            height: 25px;
        }

        &--icons {
            width: 35px;
            height: 100%;
            display: flex;
            justify-content: space-between;

            .alarm-icon {
                height: 11px;
                width: 11px;

                .alarm {
                    height: 100%;
                }
            }
        }

        &--ref {
            flex: 8 1 25px;
            padding: 5px 8px;
        }

        &--owner {
            flex: 6 1 0;
            padding: 5px 8px;
        }

        &--enquirer {
            flex: 6 1 0;
            padding: 5px 8px;
        }

        &--received {
            flex: 5 1 0;
            display: flex;
            align-items: center;
            gap: 4px;
            padding: 5px 8px;
        }

        &--expiry {
            flex: 5 1 0;
            padding: 5px 8px;
        }

        &--status {
            flex: 10 1 0;
            padding: 5px 8px;
        }

        &--subject {
            flex: 12 1 0;
            padding: 5px 8px;
        }

        &--items {
            text-align: right;
            flex: 3 2 0;
            gap: 2px;
            padding: 5px 8px;
        }
    }

    &--list {

        overflow-y: auto;
        // overflow-x: auto;
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding-bottom: 6px;
        // padding-top: 5px;
        flex: 9 1 0;
        
        padding-right: 10px;
        min-width: 620px;
    }
}

.enquiry {
    margin: 1px 0px 8px 0px;
    overflow: hidden;
    &--card {
        display: grid;
        grid-template-columns: 0px 12px auto 4px 1fr 12px auto 12px;
        grid-template-rows: 12px 20px 24px 22px 12px;

        .vertical-border {
            grid-column: 1 / 2;
            grid-row: 1 / 7;
        }

        &--enquiry-ref {
            grid-column: 3 / 4;
            grid-row: 2 / 3;
            margin-top: -1.34px;
        }

        .icon-label.enquiry-owner {
            grid-column: 5 / 6;
            grid-row: 2 / 3;
            display: flex;
            gap: 2px;

            .icon {
                width: 10px;
                height: 10px;
                margin-top: 0px;
            }

            .label {
                margin-top: -3px;
            }
        }

        .icon-label.wiz-row {
            flex: 2 1 0;

            .enq-stat-label {
                flex: 2 1 0;
            }
        }

        &--enquiry-status {
            grid-column: 5 / 6;
            grid-row: 2 / 3;
            flex: 2 1 0;
        }

        &--enquirer-name {
            grid-column: 3 / 6;
            grid-row: 3 / 4;
        }

        &--date-completed {
            grid-column: 5 / 6;
            grid-row: 3 / 4;
            display: flex;
            gap: 4px;
            max-width: 100%;
            align-self: center;
            justify-self: center;
        }

        &--enquiry-subject {
            grid-column: 3 / 6;
            grid-row: 4 / 5;
            padding-top: 5px;
            max-width: -moz-fit-content;
            max-width: fit-content;
        }

        &--right {
            grid-column: 7 / 8;
            grid-row: 2 / 5;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;

            .btn-work {
                pointer-events: none;
                opacity: 0;
                display: none;
            }

            &-row {
                width: 100%;
                display: flex;
                align-items: center;
                // justify-content: space-between;

                .icon-label {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    overflow: hidden;
                   
                    .limit-text {
                        white-space: nowrap;
                        // width: 120px;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }



                    .icon {
                        width: 14px;
                        height: 14px;
                    }
                }

                .dot {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    margin-left: 1px;
                    margin-right: 1px;
                }

                .date {
                    justify-self: right;
                }

                .received--date {
                    margin-left: 5px;
                }

                .sla-priority-bar {
                    height: 14px;
                    width: 10px;
                    margin-right: 4px;
                    transform: rotate(180deg);
                .sla-priority--value {
                        width: 100%;
                    }
                }
            }

            .received-date {
                padding-top: 5px;
            }
        }
    }
 

    &--card.has-new {
        grid-template-columns: 5.95px 12px auto 4px 1fr 12px auto 12px;
    }

    &--card.active {

        .btn-work {
            pointer-events: all;
            opacity: 1;
            display: flex;
        }

        .comm-history--icon {
            transform: rotate(90deg);
        }


    }

    &--card.active+.comms {
        width: 100%;

        .CommunicationList {
            max-height: 100px;
        }
    }

    &--card.active+.comms.expanded {
        .CommunicationList {
            width: 100%;
            max-height: -moz-fit-content;
            max-height: fit-content;
        }
    }
 

    .CommunicationList {
        transition: max-height 0.5s ease-out;
        overflow: hidden;
        max-height: 0px;
        width: 100%;
         display: flex;
        flex-direction: column;
        gap: 1px;
    }
}




.enquiry-summary {

    display: flex;
    align-items: center;
    width: 100%;
    min-width: 950px;
    margin-top: 1px;
    gap: 4px;

    padding-right: 8px;
    cursor: pointer;
    transition: box-shadow 0.1s ease-out;
border-left:1px solid #E0E0E0;
    &--vertical-bar {
        width: 5.95px;
        height: 40px;
    }

    &--icons-col {
        width: 35px;
        height: 11px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .sla-priority-bar {
            height: 100%;
            width: 8px;
            transform: rotate(180deg);
        

            .sla-priority--value {
                width: 100%;
               
            }
        }

        .alarm-icon {
            height: 100%;
            width: 11px;

            .alarm {
                height: 100%;
            }
        }

        .sla-bell-icon {
            height: 100%;
            width: 11px;
        }

        .sla-att-icon {
            height: 100%;
            width: 10.656px;
        }
    }

    &--enquiry-ref {
        flex: 8 1 25px;
        padding: 0px 8px;
    }

    &--enquiry-owner {
        flex: 6 1 0;
        padding: 0px 8px;
    }

    &--enquirer {
        flex: 6 1 0;
        padding: 0px 8px;
    }

    &--date-received {
        flex: 5 1 0;
        padding: 0px 8px;
    }

    &--expiry {
        flex: 5 1 0;
        padding: 0px 8px;
    }

    &--status {
        flex: 10 1 0;
        padding: 0px 8px;
    }

    &--subject {
        flex: 12 1 0;
        padding: 0px 8px;
    }

    &--items {
        flex: 3 2 0;
        text-align: right;
        padding: 0px 8px;
    }
}
