

.topBar {
  
  height: 52px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .left-area {
    flex: 1.75 1 0;

    font-size: 10pt;
    display: flex;
    height: 100%;
    align-items: center;
  }
  .search-area {
    position: relative;
    padding: 0px 20px;

    .search-icon-container {
      position: absolute;
      margin: 1px 0 0 10px;
      z-index: 1;
    }
    
    .offClick {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100000;
      width: 100%;
      height: 100%;
    }

    .advanced-search-dialog {
      width:100%;
      max-width: 600px;
      min-width: 500px;
      padding:10px;
      box-shadow: 6px 1px 5px 0px rgba(0,0,0,0.13);
      -webkit-box-shadow: 6px 1px 5px 0px rgba(0,0,0,0.13);
      -moz-box-shadow: 6px 1px 5px 0px rgba(0,0,0,0.13);
      background-color: var(--bs-body-bg);
      color: var(--bs-body-color);
      position: absolute;
      top: 0;
      z-index: 100000;
    }
  }

  .right-area {
    display: flex;
    padding: 0px 0px;
    height: 100%;
    align-items: center;

    .user-name {
      padding: 0px 10px;
      margin-left: 5px;
    }

    .nav-icon {
      width: 16px;
    }
  }

  input[type=text] {
    padding: 9px;
    padding-left: 29px;
    height: 25px;
    position: relative;
    left: 0;
    outline: none;
    border: 0px solid #cdcdcd;
    border-radius: 0.5em;
    width: 200px;
  }
}


@media screen and (max-width: 650px) {
  .advanced-search-dialog {
    left:-125px  ;
  }
}

.linkMailBoxSelector{
   max-Height:300px;
   overflow-Y: auto;
   height:300px
}