.comboBox {
    position: relative;   
    width:100%;
}
.comboBox-popout {
    z-index: 3;
}

.comboBox-options {
    position: absolute;
    z-index: 3;
    width: 100%;
    background-color: var(--bs-body-bg);
    border: 1px solid #CCC;
    box-shadow: 3px 1px 5px rgba(0,0,0,0.13);
    -webkit-box-shadow: 3px 1px 5px rgba(0,0,0,0.13);
    -moz-box-shadow: 3px 1px 5px rgba(0,0,0,0.13);
    max-height:92px;
    overflow-y: auto;
}

.comboBox-option {
    margin: 1px;
    padding-left: 1px;
    user-select: none;
    font-size: 8.5pt;
}

.comboBox-option:hover {
    background-color: #f0f0f0;
}