.search-body-warning {
    max-width: 400px;
}

.search-field-row {
    margin: 10px 0;
}

.search-label-col {
    text-align: left;
}

.search-label-col.search-title-col {
    margin-bottom: 10px;
}

.search-label-col input,
.search-label-col label {
    vertical-align: middle;
}

.search-label-col label {
    user-select: none;
}

.search-close-column {
    text-align: right;
}

.search-field-active {
    margin-top: 7px;
    float: left;
}

.search-field-label{
    text-align:left;
    margin-left: 10px;
}

.search-field-label.search-title {
    text-align:left;
    margin: 0;
}

.search-query-clause {
    font-size: 9pt;
    padding: 3px;
    margin-left: -18px;
    text-align: center;
    border: 1px #cfcfcf solid;
    border-radius: 5px;
    user-select: none;
    width: calc(100% + 18px);
    background-color: rgba(0, 0, 0, 0);
}

.search-query-clause:active {
    border-color: #888;
}

.g-TextBox-default.search-return-first {
    width: 85px;
}

 
.search-selected-user {
    padding: 0;
    border: 0;
    margin-top: -9px;
    color: inherit;
    display: inline-block;
    text-underline-position: under;
    vertical-align: middle;
    text-decoration: underline;
}

.search-remove-user {
    padding: 0;
    border: 0;
    margin-top: -10px;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0);
    width: 15px;
    text-align: center;
    vertical-align: middle;
}

.search-remove-user:hover {
    font-weight: bold;
}

.search-buttons {
    text-align: right;
}

.search-buttons button {
    margin: 0 8px;
    width: 100px;
}

.search-pointer-events {
    pointer-events: none;
}