.backgroundDiv{
    background: rgba(0, 0, 0, 0.6);
    width:100%;
    height:100vh;
        position: absolute;
    top:1px;left:1px;
    z-index:9998;
}

.divModal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 30% ;
   
    width: 50%;
    background-color: #fff;
    box-sizing: border-box;
    z-index: 9999;
    box-shadow: 4px 7px 19px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 4px 7px 19px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 7px 19px -2px rgba(0,0,0,0.75);
}
.divModalHeader{
    margin:20px;
    
}
.icon-padding{margin-top:-5px;}
.divModalBody{
    margin:20px;
    margin-top:0px;font-size: 13px;
}