.SearchBox{
  
    height:25px;
    border-radius:0px !important;
    
}
.searchIcon{
     width:20px;
    float:left;
     border-right:0px;
    height:23px;
    margin-top:1px;
}


.searchIcon i{
margin:4px 0px 6px 10px;display:block
}

 
.QueuesRowSelected{
    cursor: pointer;
 
    font-weight: bold;
}
.QueuesRowSelected:Hover{
    cursor: pointer;
 
    
}
.QueuesRowSelected div{
    padding-left: 10px !important;
}
.QueuesRow{
    cursor: pointer;
}
.QueuesRow:Hover{
    cursor: pointer;
}
.QueuesRow div{
    padding-left: 10px !important;
}
 
.qIcon{
 
    cursor: pointer;
    text-Align:left !important;
    padding-right:10px !important;
    left:1px;
 display: "block";
    
     margin:0px !important;
}

.qIcon:hover{
    cursor: pointer;
}

.queueRow {
    height: 33px;
    padding: 0px;
    padding-Bottom: 5px;
    padding-top: 6px;
    margin-left: 0px !important;
  }
  .queueRowRestrictedView {
    height: 33px;
    padding: 0px;
    padding-Bottom: 5px;
    padding-top: 6px;
    margin-left: 0px !important;

  }
.mailbox-refresh-button {
    border: 0;
    padding: 3px;
    background: transparent;
    margin-top: -3px;
    float: left;
    margin-left:10px;
}
.mailbox-refresh-button.selected {
    background: #D3D3D3;
}
.mailbox-refresh-button:hover {
    background: #E6E7E8;
}