 

body{

    padding:0px;
    margin:0px;
    overflow: hidden !important;
}

.layout-lg {
    display: flex;
    height: 100vh;
    width: 100hw;
    overflow: auto !important;
    overflow-y: hidden !important;
    .aside {
        min-width: 52px;
    }

    .main-layout {
        display: flex;
        flex-direction: column;
        flex: 9 1 0;
        height: 100vh;
        overflow:hidden !important;
        width: 100%;
        min-width: 650px  ;
        .top-bar {
            height: 52px;
            width:100%;
        }

        .main-content {
            flex: 9 1 0;background-color: red;
            overflow:hidden !important;
            width: 100%;
            flex-grow: 1;
            min-width: 650px  ;
            // overflow-y:auto !important; //
            
        }

        .main-content::-webkit-scrollbar {
            width: 5px;
          }
           
          .main-content::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          }
           
          .main-content::-webkit-scrollbar-thumb {
            background-color: #A5AAB3;
            outline: 0px solid #A5AAB3;
          }
    }
}

.index {
    width: 100%;
}


.btn-back {
    padding: 4px 8px;
    margin: 4px;
    border-radius: 2px;
    cursor: pointer;
    width: fit-content !important;
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 4px;
    align-items: center;
    .left-arrow {
        width: 10px;
    }
}

