.toast-dismiss-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 10000;
    padding-top: 20px;
    top: 0%;
    left: 0%;
}

.toast-dismiss {
    display: flex;
    align-items: center;
    height: fit-content;
    gap: 10px;
    width: 50%;
    max-width: 500px;
    min-width: 200px;
    padding: 6px 12px;
    box-shadow: 0px 1px 5px 1px #00000040;
}

.toast-dismiss-msg {
    flex: 2 1 0;
}

.toast-dismiss-btn {
    border: 0;
    cursor: pointer;
}

.toast-dismiss-btn:hover {
    text-decoration: underline;
}