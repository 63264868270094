.comm-logger-att-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
}

.comm-logger-text-body {
    flex: 2 1 0;
    border: 1px solid #cfcfcf;
    border-radius: 3px;
    font-size: 9pt;
    height: 60px;
    padding: 5px;
    min-width: auto;
    min-height: 25px;
    vertical-align: middle;
}

.comm-logger-attachments {
    display: flex;
    width: 100%;
    gap: 5px;
    align-items: center;
    overflow: auto;
    padding-bottom: 4px;
    margin-bottom: 4px;
    overflow: auto;
}