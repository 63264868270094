// .comm-list {
//     &--list {
//         height:96px;
//         min-height: 48px;
//         overflow:auto;
//     }
//     &--header {
//         display: flex;
//         align-items: center;
//         gap: 12px;
//         padding-left: 4px;
//         padding-right: 10px;
//         height: 24px;
       

//         &--icons {
//             width: 39.31px;
//             height: 10.656px;
//             display: flex;
//             justify-content: space-between;
//         }
//         &--received {
//             width: 80.4px;
//         }
//         &--from {
//             flex: 5 1 0;
//         }
//         &--to {
//             flex: 5 1 0;
//         }
//         &--subject {
//             flex: 11 1 0;
//         }
//         &--size {
//             flex: 2 1 0;
//         }
//     }
// }

.comm-expand-btn {
    padding: 3px 14px;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    .expand-btn {
        cursor: pointer;
    }
    .expand-btn:hover {
        text-decoration: underline;
    }
}

.details {
    background:#f3f3f3;
}
.commResize{
    background:#f3f3f3;
    height:3px;
    width:100%;
 
}
.commResizeWait{
    cursor:row-resize;
    background:#f3f3f3;
    height:3px;
    width:100%;
}
.comm-list {
table-layout: fixed;
    &--list {
        width:100%;
        height:96px;

        min-height: 48px;
        overflow-x:scroll;
        display:block;
        }
background-color: #F5F5F5;
     

}
.scrollFF{
    overflow:auto;
    display:block;
}
 
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;/* even columns width , fix width of table too*/
}
// thead {
//     width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
// }
thead {
    width: 100%/* scrollbar is average 1em/16px width, remove it from thead width */
}

.noScrollBar .col1{
    width: 15px
}
.noScrollBar .col2{
    width: 11px
}
.noScrollBar .col3{
    width: 15px
}
.noScrollBar .col4{
    width: 12%
}
.noScrollBar .col5{
    width: 20%
}
.noScrollBar .col6{
    width: 20%
}
.noScrollBar .col7{
    width: 35%
}
.noScrollBar .col8{
    width: 7%
}

.tHeaddWithScroll{
    width: calc(100% - 5px);
}