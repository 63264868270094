.popped-wizard-wrap {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.popped-wizard {
min-width: 550px;
    overflow: hidden;

    

    display: flex;
    flex-direction: column;
    overflow: hidden;

    &--content {
        flex: 9 1 0;
        display: flex;
        flex-direction: row-reverse;
        overflow: hidden;
        margin-top: 5px;
    }
    &--left-title {
        // padding: 5px;
        margin-left: 4px;
        grid-row: 1 / 2;
        grid-column: 1 / 2;

    }

    &--right-title {
        padding: 5px;
        grid-row: 1 / 2;
        grid-column: 2 / 3;
        align-self: center;
    }

    &--tabs {
        padding-top: 8px;
        grid-column: 1 / 3;
        grid-row: 1 / 2;

        &--tab-list {
            display: flex;
        }

        &--head {
            margin: 0px;
            margin-right: 10px;
            &--title {
                padding: 11px;
                padding-left: 18px;
                font-weight: bold;
            }
        }
    }

    &--workflow {
        flex: 6 1 0;
        overflow-y: scroll;
        padding-right: 10px;
        padding-bottom: 10px;
    }

    &--preview {
        flex: 6 1 0;
        overflow-y: scroll;
        // overflow-x: hidden;
        display: flex;
        padding-right: 10px;
        padding-left: 4px;
        padding-bottom: 4px;
    }
}

@media screen and (max-width: 1000px) {
    .popped-wizard-wrap {
        overflow: auto;
    }
    .popped-wizard {
        display: flex;
        flex-direction: column;
        height: fit-content;
        overflow: hidden; //auto
        padding-right: 0px;

        // &--tabs {
        //     // grid-column: 1 / 2;
        //     // grid-row: 1 / 2;
        // }

        &--content {
            display: block;
            flex-direction: column;
        }

        &--right-title {
            margin-left: 4px;
        }

        &--workflow {
            padding-left: 4px;
            overflow: hidden;
            height: fit-content;
           
        }

        &--preview {
            margin-top: 5px;
            overflow: hidden;
            height: fit-content;
        }
    }
}

.popped-wizard--preview > .enquiry-history{
    overflow: auto;
}