.find-dialog-options {
    border-width: 1px;
    border-style: solid;
    width: auto;
    height: 200px;
    overflow: auto;
    scroll-behavior: smooth;
}

.find-dialog-option {
    padding: 3px 5px;
    user-select: none;
}