.userPrefWrapper{
    display:flex;
    height:100%;
}
.userPrefWrapper .col1{
    flex-shrink: 0; width: 200px;
    background-color: #f3f3f3;
}

.col1 .header, .col2 .header{
    font-weight: bold;
    padding:10px;
    font-size:10pt;
    border-bottom:solid 1px #ddd;
}
.col1 .selectedPreference{
    font-weight: bold;
    padding:10px 10px 10px 10px;
    
}
.col1 .preference{
    padding:10px 10px 10px 10px;
}

.userPrefWrapper .col2{
    flex-grow: 1;
    background:#fff;
}

.col2 .header{
    padding-left:20px;
}