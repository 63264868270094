/* enquiry expiration and pend alarm settings step */
.list-float {
    position: relative;
    margin-top: 10px;
}

.list-float-label {
    position: absolute;
    top: -3px;
    background: white;
}

.list-float-content {
    border: 1px solid #cfcfcf;
    border-radius: 3px;
    margin-top: 10px;
    padding-top: 10px;
}

.quickies {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 10px;
}
.quicky {
    margin: 2px 10px;
    display: flex;
    align-items: center;
}
