$brp-lg: 1065px;
$Unbrp-lg: 1066px;

.attachment-container {
    max-width: 200px;
    width: fit-content;
    min-width: 200px;
    height: 28px;
   z-index: 25000;
    display: flex;
    flex-direction: column;
    gap: 1px;
 
    button {
        border: 0;
        background: none;
        padding: 0;
        margin: 0;
    }

    .attachment {

        display: flex;
        flex: 9 1 0;
        justify-content: space-between;
        // height: 100%;

        &--details {
            display: flex;
            flex: 1 1 0;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            max-width: 92.5%;


            .attachment-icon {
                width: 25px;
                height: 25px;
                padding: 5px;
            }

            

            &--file-details {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                flex: 9 1 0;
                overflow: hidden;
                padding-right: 2px;

                &--file-name {
                    padding-top: 3px;
                    padding-bottom: 1px;
     
                }

                &--file-size {
                    padding-top: 1px;
             
                    padding-bottom: 4px;
                }
            }
        }

       

        &--btn-dropdown {
            width: 20px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

          
        }

         
    }

    .dropdown-menu-options {
        z-index: 99999 !important;
        display: flex;
        flex-direction: column;

        &--option {
            display: flex;
            align-items: center;
            padding: 2px 6px;
            margin-left: 20px;
            margin-right: 20px;
            cursor: pointer;
        }

     
    }
}

.dropdown-menu-options-buffer {
    background: transparent;
    width: 238px;
    height: 70px;
    margin-left: -20px;
    margin-bottom: -16px;
}

.enquiry-history {
    height: 100%;
    flex: 9 1 0;
    display: flex;
    flex-direction: column;
    margin-left:-10px;
 
    padding-bottom:-20px !important;
    // &--head {
    //     font-size: $font-size--md;
    //     font-weight: $font-weight--bold;
    //     color: $color-grey--light-01;
    //     padding-bottom: 10px;
    // }
    &--enquiry {
        padding-left: 5px;
    }
}
 
.email-preview {
   
    flex: 6 1 0;
    display: flex;
    flex-direction: column;
    overflow: auto;

    padding:1px;
    &--details {
        flex: 9 1 0;
        margin: 6px 0px 10px;
     
        display: flex;
        flex-direction: column;
        gap: 5px;
        
        &--subject-row {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            padding: 10px 15px 0px;
            .icon {
                height: 20px;
                width: 20px;
            }
        }

        &--addresses-row {
            display: flex;
            align-items: center;
            gap: 10px; 
            padding: 0px 15px 0px;
            .profile {
                width: 37px;
                height: 37px;
                border-radius: 50%;
                margin-top: 0.5px;
                cursor: default;
                display: flex;
                align-items: center;
                justify-content: center;
            }
              .from-to-cc {
                display: flex;
                width: 100%;
                flex-direction: column;
                justify-content: space-between;
                .to-cc {
                    display: flex;
                    gap: 10px;
                    .to,
                    .cc {
                        display: flex;
                        gap: 4px;
                        .label {
                            cursor: default;
                        }                      
                    }
                    overflow-x: hidden;
                    height:30px;
                }
            }
        }

        &--priority-row {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 0px 15px 0px;
            .exclamation {
                height: 15px;
                width: 20px;
                margin: -5px;
                color: #CE0000;
            }
            
            .arrow {
                height: 12px;
                width: 16px;
                margin: -3px;
                color: #1B7FF4;
            }
        }

        &--attachments-row {
            display: flex;
            flex-direction: column;
            padding: 0px 15px 0px;
           
            margin-top:8px;
            .attachments {
                // overflow: hidden scroll;
                // max-height: 150px;
            }

            .attachment-actions {
                display: flex;
                gap: 20px;
                .total {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .double-chevron {
                        transform: rotate(180deg);
                    }

                    .double-chevron.flipped {
                        transform: rotate(0deg);
                    }
                }

                .download-all {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }

            }

            .attachment-actions.more-4 {

                .total {
                    cursor: pointer;
                }

            }
        }
        &--body-row {
         
            overflow: auto;
            height: fit-content;
            flex: 2 1 0;
        }
    }
}
.att-icon-red {
    color: #AE0402;
}

.att-icon-blue {
    color: #2F5098;
}

.att-icon-green {
    color: #305938;
}

.att-icon-orange {
    color: #C43E1C;
}

.att-icon-grey {
    color: #727272;
}

.att-icon-black {
    color: #000000;
}

.att-icon-pink {
    color: #E08095;
}

.att-icon-gold {
    color: #DCA300;
}

.att-icon-purple {
    color: #7F3979;
}

.attachmentIcon {
    font-Size: 10pt;
    padding-Left: 1px;
    margin-Top: 3px !important;

}

.chevronIcon {
    font-Size: 8pt;
    padding-Left: 1px;
    margin-Top: 6px !important;

}

.attachmentCount {
    font-Size: 10pt;
    margin-Top: 5px;
    margin-left: 4px;
    margin-right: 2px;

}

.popOutEmailPreviewIcon {
    font-Size: 10pt;
    padding-Left: 1px;
    margin-Top: 3px !important;
    margin-left: 10px;

}

.attachmentBlockContainer {

    width: 187px;
    float: left;
    margin: 0px !important;
    padding: 0px !important;
    padding-top: 5px !important;
}

.attachmentBlock {
    border: solid 1px #ddd;
    padding: 0px;
    margin-right: 7px;
    display: flex;

}

.attachmentBlock>div:nth-of-type(1):hover,
.attachmentBlock>div:nth-of-type(2):hover {
    background: #F3F1F1;
}



.attachmentBlock>div:nth-of-type(1):hover,
.attachmentBlock>div:nth-of-type(2):hover,
.attachmentBlock>div:nth-of-type(3):hover {
    background: #F3F1F1;
    cursor: pointer;
}


.attachmentBlock>div:nth-of-type(1)>img {
    margin: auto;

}

.attachmentBlock>div:nth-of-type(2)>h1 {
    font-size: 9pt;
    margin: 0px !important;
    padding: 0px !important;
    padding-top: 3px !important;
    width: 70px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.attachmentBlock>div:nth-of-type(2) {
    width: 150px;
    padding-left: 10px;
    padding-right: 10px;
}

.attachmentBlock>div:nth-of-type(1) {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.attachmentBlock>div:nth-of-type(3) {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.attachmentBlock>div:nth-of-type(2)>span {
    font-size: 7pt;
}

.attachmentRow {
    padding-top: 10px;
    overflow-x: auto;
}

.attachmentsCombinedRow {
    display: flex;
    margin-top: 5px;
}

.attachmentsCombinedRow>div:nth-of-type(2) {
    font-size: 9pt;
    margin: 0px;
    padding: 0px;
    margin-left: 12px;
}

.attachmentsCombinedRow>div:nth-of-type(2) i {
    font-size: 7pt;
    margin-top: 2px;
    margin-right: 3px;
    float: left;
}

.attachmentBlockOptions {
    display: flex;
    position: relative;
}





.subjectRow {
    width: 100%;
    border: solid 1px #E0E0E0;
    background: white;
    font-Size: 9pt;
}

.attachmentDropdownContainer {
    display: none;
    position: relative;
    top: 7px;
    margin-left: -130px;


}

.MainAttachmentOptionsContainer {
    position: absolute;
    border: solid 10px transparent;
    border-top: 0px;
    border-left: 0px;
}

.attachmentDropdownRow {
    display: flex;
    padding: 5px;
    border: solid 1px #f3f3f3;
    width: 180px;
    background-color: #fff;
}

.attachmentDropdownRow:hover {
    display: flex;
    padding: 5px;
    border: solid 1px #f3f3f3;
    border-bottom: solid 1px #F7F9FA;
    width: 180px;
    background: #F7F9FA;
}

.attachmentDropdownRow>div:nth-of-type(1) {
    flex: 20
}

.attachmentDropdownRow>div:nth-of-type(2) {
    flex: 130
}

.attachmentDropdownRow>div:nth-of-type(3) {
    flex: 30;
}


.attachmentDropdownRow>div:nth-of-type(2)>h1 {
    font-size: 8pt;
    margin: 0px;
    padding: 0px;
    margin-bottom: -3px;
    width: 70px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.attachmentDropdownRow>div:nth-of-type(2) {
    margin-left: 10px;
    margin-right: 10px;
}

.attachmentDropdownRow>div:nth-of-type(2)>span {
    font-size: 6.5pt;
}

.attachmentDropdownRow>div:nth-of-type(1)>img {
    margin-top: 50%;
    margin-bottom: 50%;
}

.attachmentDropdownRow>div:nth-of-type(3)>i {
    font-size: 6.5pt;
    display: none;
}

.attachmentDropdownRow:hover>div:nth-of-type(3)>i {
    font-size: 6.5pt;
    display: block;
    position: relative;
    float: right;
    margin-top: 70%;
    padding-right: 2px;

}

.attachmentDropdownRow:hover {
    cursor: pointer;
}

.optionsRow {
    display: flex;
    width: 180px;
    background: #F7F9FA !important;
}

.optionsRow :hover {
    background: #F3F1F1 !important;
}



.optionsRow>div:nth-of-type(1) {
    flex: 20
}

.optionsRow>div:nth-of-type(2) {
    flex: 130
}

.optionsRow>div:nth-of-type(3) {
    flex: 30;
}

.optionsRow>div:nth-of-type(2)>h1 {
    font-size: 8pt;
    margin: 8px 10px 8px 10px;
    padding: 0px;
}

.attachmentDropdownParentRow {
    border: solid 1px #f3f3f3;
    width: 180px;
    position: absolute;
}

.optionsRow:hover {
    background-color: #EEEEEE;
    cursor: pointer;
}

.topRowMail {
    display: flex;
    height: 33px;
}

.topRowMail>div:nth-of-type(1) {
    flex-direction: column-reverse;
    flex: 80%;
    margin-right: 100px;
    align-content: right;
}

#topRowClip:hover {
    background: #F5F5F8;
    width: 50px !important;
    cursor: pointer;
}

#topRowClip {
    width: 50px !important;
    max-width: 50px !important;

    height: 33px;
}



.invisible {
    display: none;
}

.visible {
    display: block !important;
}

.menuBackA {
    background: red !important;
    width: 200px !important;
    height: 120px !important;
    position: absolute;
    display: block;
}

@media screen and (min-width: $Unbrp-lg) {
    .bodyRow {
        width: 100%;
        border: solid 1px #f3f3f3;
        font-Size: 10.5pt;
        padding: 8px;
        margin-top: 7px;
        margin-bottom: 0px;
        overflow-y: scroll !important;
        background-color: #fff !important;
    }

    .bodyRow div {

        height: 100% !important;

    }


}

@media screen and (max-width: $brp-lg) {
    .bodyRow {
        width: 100%;
        border: solid 1px #E0E0E0;
        font-Size: 10.5pt;
        padding: 8px;
        margin-top: 7px;
        margin-bottom: 0px;
        overflow: scroll !important;
        overflow-y: scroll !important;
        background-color: #fff;
    }

    #emailBodyiFrame {
        overflow: scroll !important;
        overflow-y: scroll !important;
    }
}
